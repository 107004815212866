*{
  margin:0;
  padding:0;
  box-sizing: border-box;
}
.total{
  height: 74vh;
  width: 39vw;
  margin-left: 60vh;
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  align-items: center;
  margin-top: 10vh;
  background-color:#E9D5DA;
  border: none;
  border-radius: 32px;
  box-shadow: 10px 10px 5px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 10px 10px 5px 0px rgb(139 118 118 / 75%);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}
.fullname,.email,.gender{
  height: 5vh;
  width: 20vw;
  margin-top: 10vh;
  font-size: larger;
}
button{
  margin-top: 5vh;
  width: 10vw;
  height: 8vh;
  background-color: darkgrey;
  font-size: larger;
  border-radius: 8%;
  border: none;
}
p{
  font-size: x-large;
}
.image{
  height: 10vh;
  width: 10vw;
  margin-top: 16px;
}

